import React from "react";
import { Link } from "react-router-dom";


export function NotFound() {
    return (
        <div className="flex flex-col font-display font-semibold text-frog-dark items-center justify-center h-screen w-screen">
        <h1 className="text-3xl mb-12">
          앗, 페이지를 찾을 수 없어요.
        </h1>
        <div className="mx-24 flex flex-col justify-center">
            
            <span className="mt-12 jutify-center text-center text-xl font-light">
            <Link to="/"> 홈으로</Link>
            </span>
        </div>
        </div>
    )
}