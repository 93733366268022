/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AdminRole {
  MANAGER = "MANAGER",
  MEMBER = "MEMBER",
  SUPER = "SUPER",
}

export enum NewsCategoryType {
  ANNOUNCEMENT = "ANNOUNCEMENT",
  COMMUNITY_AND_UX = "COMMUNITY_AND_UX",
  ECONOMIC_MODEL_AND_REWARDS = "ECONOMIC_MODEL_AND_REWARDS",
  EVENT = "EVENT",
  GAME_PLAY_GUIDE = "GAME_PLAY_GUIDE",
  OTHER = "OTHER",
  TECH_INSIGHT = "TECH_INSIGHT",
  TRENDS = "TRENDS",
  UPDATES_AND_ROADMAP = "UPDATES_AND_ROADMAP",
}

export interface CreateAdminAccountInput {
  username: string;
  name: string;
  displayName: string;
  password: string;
}

export interface CreateCharacterByAdmin {
  name: string;
  nameEn?: string | null;
  description?: string | null;
  descriptionEn?: string | null;
  baseHp?: number | null;
  baseSpeed?: number | null;
  baseAgility?: number | null;
  baseDefense?: number | null;
  tierId?: number | null;
}

export interface CreateNewsInput {
  newsType?: NewsCategoryType | null;
  thumbnail?: string | null;
  title?: string | null;
  subTitle?: string | null;
  editor?: string | null;
  linkTo?: string | null;
  externalLinkTo?: string | null;
  content?: string | null;
}

export interface EditAdminInfo {
  active?: number | null;
  name?: string | null;
  displayName?: string | null;
  password?: string | null;
  adminRole?: AdminRole | null;
  profileImageUrl?: string | null;
  adminId?: number | null;
  newPassword?: string | null;
}

export interface UpdateCharacterByAdmin {
  name?: string | null;
  nameEn?: string | null;
  description?: string | null;
  descriptionEn?: string | null;
  baseHp?: number | null;
  baseSpeed?: number | null;
  baseAgility?: number | null;
  baseDefense?: number | null;
  tierId?: number | null;
  characterId: number;
}

export interface UpdateNewsInput {
  newsType?: NewsCategoryType | null;
  thumbnail?: string | null;
  title?: string | null;
  subTitle?: string | null;
  editor?: string | null;
  linkTo?: string | null;
  externalLinkTo?: string | null;
  content?: string | null;
  newsId: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
