import React from 'react';
import { BrowserRouter,  Route, Routes } from 'react-router-dom';
import { useMe } from '../hooks/useMe';
import { AdminRole } from '../__generated__/globalTypes';
import { NotFound } from '../pages/common/notfound';
import OpeningComponent from '../pages/common/opening';
import Home from '@/pages/home/home';
import Shop from '@/pages/shop/shop';
import NewsMain from '@/pages/media/news/news';
import NewsAdd from '@/pages/media/news/news-add';
import Character from '@/pages/shop/character';
import Members from '@/pages/admins/members';
import MyInfo from '@/pages/my/my-info';


const SuperAdminRoutes = [
    <Route key={0} path="*"  element={<NotFound />} />,
    <Route key={1} path="/" element={<Home />} />,
    <Route key={2} path="/shop/items" element={<Shop />} />,
    <Route key={3} path="/shop/characters" element={<Character />} />,

    <Route key={11} path="/media/news" element={<NewsMain />} />,
    <Route key={12} path="/media/news/create" element={<NewsAdd />} />,

    <Route key={70} path="/my" element={<MyInfo />} />,
    <Route key={77} path="/super/admin-members" element={<Members />} />
]

const ManagerAdminRoutes = [
    <Route key={0} path="*"  element={<NotFound />} />,
    <Route key={1} path="/" element={<Home />} />,
       <Route key={2} path="/shop/items" element={<Shop />} />,
       <Route key={3} path="/shop/characters" element={<Character />} />,

       <Route key={11} path="/media/news" element={<NewsMain />} />,
       <Route key={12} path="/media/news/create" element={<NewsAdd />} />,
       <Route key={70} path="/my" element={<MyInfo />} />,
]

const MemberAdminRoutes = [
    <Route key={0} path="*"  element={<NotFound />} />,
    <Route key={1} path="/" element={<Home />} />,
    <Route key={2} path="/shop/items" element={<Shop />} />,
    <Route key={3} path="/shop/characters" element={<Character />} />,

    <Route key={11} path="/media/news" element={<NewsMain />} />,
    <Route key={12} path="/media/news/create" element={<NewsAdd />} />,
    <Route key={70} path="/my" element={<MyInfo />} />,
]



export const LoggedInRouter = () => { 
    const {data, loading} = useMe();
    if(loading) {
        return (
            <OpeningComponent />
        )
    } else {
        return (
            <BrowserRouter>
                    <Routes>
                        {data?.useAdmin?.adminRole === AdminRole.SUPER && SuperAdminRoutes}
                        {data?.useAdmin?.adminRole === AdminRole.MANAGER && ManagerAdminRoutes}
                        {data?.useAdmin?.adminRole === AdminRole.MEMBER && MemberAdminRoutes}
                    </Routes>
            </BrowserRouter>
        )
    }
}