export const greetings = [
    "안녕하세요",
    "반가워요",
    "좋은 하루입니다",
    "어서 오세요",
    "환영합니다",
    "오늘도 좋은 하루 되세요",
    "즐거운 하루입니다",
    "기분 좋은 하루입니다",
    "행복한 하루입니다",
    "안녕하세요! 반가워요",
    "안녕하세요! 오늘 어때요?",
    "안녕하세요! 잘 지내셨나요?",
    "안녕하세요! 기분 좋은 하루 되세요",
    "안녕하세요! 행복한 하루 되세요",
    "안녕하세요! 오늘도 화이팅!",
    "안녕하세요! 멋진 하루 되세요",
    "안녕하세요! 반갑습니다!"
  ];