
import React, { Fragment, useRef, useState } from "react";
import { Dialog, DialogTitle, DialogPanel, Transition, TransitionChild } from "@headlessui/react";
import { XCircleIcon} from '@heroicons/react/24/outline';
import { IconButton } from "@material-tailwind/react";


const MyModal = (props:any) => {
   const {open, close, header} = props;
	return (
     <>
        {open ? ( 
           <Transition appear show={true} as={Fragment}  >
            <Dialog as="div" className="relative z-20" onClose={close}>
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray bg-opacity-50" onClick={close}/>
            </TransitionChild>
            <div className="fixed inset-0 overflow-y-auto" >
              <div className="flex h-full items-center justify-center p-4 text-center">
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <DialogPanel className="w-full max-w-4xl transform overflow-y-scroll h-full rounded-2xl bg-white p-6 text-left align-middle shadow-md transition-all">
                 <div className="flex flex-row justify-between items-center"> 
                  <DialogTitle
                      as="h3"
                      className="text-lg font-bold leading-6 text-black flex flex-row justify-between w-full items-center px-4"
                    >
                     {header}
                    </DialogTitle>
                    <IconButton
                    aria-label="write-notice"
                    size="lg"
                    onClick={close}
                >
                    <XCircleIcon width={24} height={24} color="#323232" />
                </IconButton>
                 </div>  
                     <main className="flex  ">
                       {props.children}
                     </main>
                  </DialogPanel>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
          </Transition>
      ): null}
      </>
 
	);
}

export default MyModal;
