import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_DIVIDER_COMMAND } from '@/plugins/Divider/commands';
import {MinusIcon} from '@heroicons/react/20/solid';
import { ActionsContainer } from '../ActionsContainer';
import { ActionButton } from '../ActionButton';

export const DividerAction = () => {
   const [editor] = useLexicalComposerContext();

   const handleOnClick = () => {
      editor.dispatchCommand(INSERT_DIVIDER_COMMAND, undefined);
   };

   return (
      <ActionsContainer title="">
         <ActionButton onClick={handleOnClick} tooltip="디바이더">
         <MinusIcon className="w-5 h-5" />
         </ActionButton>
      </ActionsContainer>
   );
};
