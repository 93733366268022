import React from 'react';
import { Footer } from './footer';
import Navbar from './navbar/navbar';
import { Sidebar } from './navbar/sidebar';

interface LayoutProps {
    children: React.ReactNode;
    pageTitle?: string;
    canGoBack?: boolean;
    hasNavbar: boolean;
    isFooter?: boolean;
    isSidebar?: boolean;
  }



export default function Layout({
  isSidebar, hasNavbar, children, isFooter, pageTitle, 
} : LayoutProps) {
    return (
      <main className="flex flex-col w-screen h-screen overflow-hidden bg-white">
          <div className="flex h-screen w-full  flex-grow fixed left-0 top-0">
              {isSidebar && <Sidebar />}
              <section className="flex flex-col w-full md:justify-center self-center items-center">
                  {children}
              </section>
          </div>
          {isFooter && <Footer />}
      </main>
    )
}
