import React from 'react';

type Option = string | { value: string; label: string };

const SelectField = React.memo(({ value, onChange, onBlur, options }: { value: any; onChange: any; onBlur: any; options: Option[] }) => {
  return (
    <select
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className="md:text-base mb-1 focus:outline-none bg-transparent focus:shadow-none rounded-xl focus:ring-0 ring-0 ring-transparent focus:border-green border-gray border text-black font-display w-full flex p-3 placeholder:text-gray"
    >
      {options.map((option: Option, index: number) => {
        const optionValue = typeof option === 'string' ? option : option.value;
        const optionLabel = typeof option === 'string' ? option : option.label;
        return (
          <option key={index} value={optionValue}>
            {optionLabel}
          </option>
        );
      })}
    </select>
  );
});

export default SelectField;