import Layout from '@/components/layouts/layout'
import React, {useEffect, useRef, useState} from 'react'
import { 
    Breadcrumbs,
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
} from "@material-tailwind/react";
import {
    ArrowDownTrayIcon,
    MagnifyingGlassIcon,
    PlusCircleIcon,
    DocumentMagnifyingGlassIcon, 
    PencilSquareIcon
  } from "@heroicons/react/20/solid";
  import {loadAuth2WithProps} from 'gapi-script';
  import DatePicker, { CalendarContainer } from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css"; 
import { useQuery } from '@apollo/client';
import { GET_ALL_CHARACTERS } from '@/apis/query';
import { seeAllCharactrersByAdmin } from '@/__generated__/seeAllCharactrersByAdmin';
import MyModal from '@/components/Modal/my-modal';
import CharacterForm from '@/components/characters/character-form';
import NewCharacterAddForm from '@/components/characters/new-character-form';


  const TABLE_HEAD = [
    {
      head: "id",
      customeStyle: "!text-left",
    },
    {
      head: "Name",
      customeStyle: "text-center",
    },
    {
      head: "Description",
      customeStyle: "text-center",
    },
    {
      head: "tier",
      customeStyle: "text-center",
    },
    {
      head: "체력",
      customeStyle: "text-right",
    },
    {
      head: "이동속도",
      customeStyle: "text-right",
    },
    {
      head: "방어력",
      customeStyle: "text-right",
    },
    {
        head: "민첩성",
        customeStyle: "text-right",
      },
      {
        head: "Edit",
        customeStyle: "text-right",
      },
  ];



export default function Character() {
    const [search, setSearch] = useState("");
    const onChange = ({ target }: any) => setSearch(target.value);
    const {data, loading}=useQuery<seeAllCharactrersByAdmin>(GET_ALL_CHARACTERS);

    const [modalOpen, setModalOpen] = useState(false);
    const [selectedCharacter, setSelectedCharacter] = useState<any>(null);
  
    const openModal = (character:any) => {
      setSelectedCharacter(character);
      setModalOpen(true);
    };
  
    const closeModal = () => {
      setModalOpen(false);
      setSelectedCharacter(null);
    };

    //새로운 캐릭터 등록
    const [showCharacterForm, setShowCharacterForm] = useState(false);
    const [characterToEdit, setCharacterToEdit] = useState<any>(null);
  
    const openNewCharacterForm = () => {
      setCharacterToEdit({
        nameEn: "",
        description: "",
        tierId: 1,
        baseHp: "",
        baseAgility: "",
        baseDefense: "",
        baseSpeed: "",
      });
      setShowCharacterForm(true);
    };
  
    const closeCharacterForm = () => {
      setShowCharacterForm(false);
      setCharacterToEdit(null);
    };

    

  
    return (
        <Layout hasNavbar={true} isFooter={false} isSidebar={true}>
        <div className="flex flex-col w-full min-h-screen overflow-y-scroll bg-white p-10">
            <div className="font-body text-4xl mb-3">
            Characters
            </div>

            <Breadcrumbs className='p-1 hover:text-mainpurple transition-all'>
                 <a href="#" className="font-sans opacity-60 hover:text-mainpurple">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                >
                                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                                </svg>
                            </a>
                    <a href="#" className="font-sans opacity-60 hover:text-mainpurple">
                    Shop
                    </a>
                    <a href="#" className="font-sans hover:text-mainpurple font-semibold text-secondarypurple">Characters</a>
                    </Breadcrumbs>
                    <Card className="h-full w-full mt-6">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
          <div>
            <Typography variant="h5" color="black">
              캐릭터
            </Typography>
            <Typography color="gray" className="mt-1 font-display font-normal">
              캐릭터를 등록, 수정할 수 있는 페이지입니다.
            </Typography>
          </div>
          <div className="flex w-full shrink-0 gap-2 md:w-max">
            <div className="w-full md:w-72 items-center">
              <Input
                variant="standard"
                color="black"
                size="lg"
                className="placeholder-text-gray text-black border-b-2 mr-4 font-display items-center focus:outline-none focus:border-b-2 focus:border-primary-green"
                placeholder='검색어를 입력하세요.'
                value={search}
                onChange={onChange}
                icon={<MagnifyingGlassIcon className="h-5 w-5 mb-2" />}
              />
            </div>
            <Button className="flex items-center gap-3 text-black" size="sm" onClick={() => openNewCharacterForm()}>
              <PlusCircleIcon strokeWidth={2} className="h-4 w-4 text-black" /> NEW 
            </Button>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll !px-0 py-2">
      <table className="w-full min-w-max table-auto">
        <thead>
          <tr>
            {TABLE_HEAD.map(({ head, customeStyle }) => (
              <th
                key={head}
                className={`border-b border-gray-300 bg-[#f2f6f6] !p-4 pb-4 ${customeStyle}`}
              >
                <Typography
                  color="black"
                  variant="small"
                  className="!font-bold"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="mt-3">
          {data?.seeAllCharactrersByAdmin?.results?.map((c, index: number) => {
            const isLast = data?.seeAllCharactrersByAdmin?.results
              ? index === data.seeAllCharactrersByAdmin.results.length - 1
              : false;
            const classes = isLast
              ? "!p-4"
              : "!p-4 border-b border-gray-300";
            return (
              <tr key={c.id} className="h-24">
                <td className={classes}>
                  <div className="block items-center justify-center text-center">
                    <Typography
                      variant="small"
                      className="!font-normal text-black"
                    >
                      {c.id}
                    </Typography>
                  </div>
                </td>
                <td className={classes}>
                  <div className="block items-center justify-center text-center">
                    <Typography
                      variant="small"
                      className="!font-normal text-black"
                    >
                      {c.nameEn}
                    </Typography>
                  </div>
                </td>
                <td className={`${classes} w-[30%]`}>
                  <div className="block items-center justify-center text-wrap line-clamp-3 text-center">
                    <Typography
                      variant="small"
                      className="!font-normal text-black break-words"
                    >
                      {c.description}
                    </Typography>
                  </div>
                </td>
                <td className={classes}>
                  <div className="block items-center justify-center text-center">
                    <Typography
                      variant="small"
                      className="!font-normal text-black"
                    >
                      {c.tier?.tierName}
                    </Typography>
                  </div>
                </td>
                <td className={classes}>
                  <div className="block items-center justify-end text-right">
                    <Typography
                      variant="small"
                      className="!font-normal text-black"
                    >
                      {c.baseHp}
                    </Typography>
                  </div>
                </td>
                <td className={classes}>
                  <div className="block items-center justify-end text-right">
                    <Typography
                      variant="small"
                      className="!font-normal text-black"
                    >
                      {c.baseSpeed}m/s
                    </Typography>
                  </div>
                </td>
                <td className={classes}>
                  <div className="flex items-center justify-end text-right">
                    <Typography
                      variant="small"
                      className="!font-normal text-black"
                    >
                      {c.baseDefense}
                    </Typography>
                  </div>
                </td>
                <td className={classes}>
                  <div className="block items-center justify-end text-right">
                    <Typography
                      variant="small"
                      className="!font-normal text-black"
                    >
                      {c.baseAgility}
                    </Typography>
                  </div>
                </td>
                <td className={classes}>
                  <div className="block justify-center text-center">
                    <IconButton variant="text" size="sm" onClick={() => openModal(c)}>
                      <PencilSquareIcon className="h-5 w-5 text-black ml-2 hover:text-gray" />
                    </IconButton>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {selectedCharacter && (
        <MyModal open={modalOpen} close={closeModal} header={`캐릭터 수정: ${selectedCharacter?.nameEn}`}>
          <CharacterForm character={selectedCharacter} closeModal={closeModal} />
        </MyModal>
      )}
      {showCharacterForm && (
        <MyModal open={showCharacterForm} close={closeCharacterForm} header={`캐릭터 신규 등록`}>
          <NewCharacterAddForm character={characterToEdit} closeModal={closeCharacterForm} />
        </MyModal>
      )}
    </CardBody>
      </Card>
      
             </div>
        </Layout>
    )
}