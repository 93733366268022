import React, { PropsWithChildren } from 'react';
import '../ActionButton/styles.css';
import {} from 'react-icons/fa'

interface ActionsContainerProps extends PropsWithChildren {
   title: string | React.ReactNode;
}

export const ActionsContainer: React.FC<ActionsContainerProps> = ({
   title,
   children,
}) => {
   return (
         <div
            className="mt-5 items-center"
            style={{
               display: 'flex',
               flexDirection: 'row',
               flexWrap: 'nowrap',
               gap: '8px',
               marginTop: '4px',
            }}
         >
            {children}
         </div>
   );
};
