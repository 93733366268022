import {ApolloClient, createHttpLink, InMemoryCache, makeVar} from '@apollo/client';
import { LOCALSTORAGE_TOKEN } from './constants';
import {setContext} from '@apollo/client/link/context';
import {onError} from "@apollo/client/link/error"





const token = localStorage.getItem(LOCALSTORAGE_TOKEN);


export const isLoggedInVar = makeVar(Boolean(token));
export const authTokenVar = makeVar(token);
export const BASE_URL = process.env.REACT_APP_BASE_URL;



const httpLink = createHttpLink({
    uri: `${BASE_URL}/graphql`
});

const authLink = setContext((_, {headers}) => {
    return {
        headers: {
            ...headers, 
            "x-jwt": authTokenVar() || "",
        },
    };
});

export const logUserOut = () => {
    localStorage.removeItem(LOCALSTORAGE_TOKEN);
    isLoggedInVar(false);
    authTokenVar("");
};

const onErrorLink = onError(({graphQLErrors, networkError}) => {
    if(graphQLErrors) 
     graphQLErrors.forEach(({message, locations, path}) => 
     console.log(
        `[graphql error]: message: ${message}, 에러발생한곳: ${locations}, path: ${path}`
     ));
     if(networkError) {
        console.log("네트워크 에러입니다", networkError);
     }
});

const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                isLoggedIn: {
                    read() {
                        return isLoggedInVar();
                    },
                },
                token: {
                    read() {
                        return authTokenVar();
                    }
                }
            }
        }
    }
});

export const client = new ApolloClient({
    link: authLink.concat(onErrorLink).concat(httpLink), 
    cache,
    defaultOptions: {
        watchQuery: {
            nextFetchPolicy(
                currentFetchPolicy, 
                {
                    reason, 
                    options,
                    initialFetchPolicy,
                    observable
                }
            ) {
                if(reason === "variables-changed") {
                    return "network-only"
                } if(
                    currentFetchPolicy === "network-only" ||
                    currentFetchPolicy === "cache-and-network"
                ) {
                    return "cache-and-network";
                }
                return currentFetchPolicy;
            }
        }
    }
})