import { updateCharacterByAdmin, updateCharacterByAdminVariables } from "@/__generated__/updateCharacterByAdmin";
import { UPDATE_CHARACTERS } from "@/apis/mutation";
import { useMutation } from "@apollo/client";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Toast from "../toasters/toast";
import { SmallLoader } from "../loader/small-loader";

const CharacterForm = ({ character, closeModal }: any) => {
    const [formData, setFormData] = useState(character);
    const [isModified, setIsModified] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const initialFormData = useRef(character);


    const onCompleted = async (data: updateCharacterByAdmin) => {
      const {updateCharacterByAdmin: {ok, error}} = data;
      if(ok) {
        setShowSuccessAlert(true);
            setTimeout(() => {
                setShowSuccessAlert(false);
                closeModal();
            }, 2000);
      } else {
        setShowErrorAlert(true);
        setTimeout(() => {
            setShowErrorAlert(false);
        }, 2000);

      }
    }

    const [updateCharacterByAdmin, {loading: updating}] = useMutation<updateCharacterByAdmin, updateCharacterByAdminVariables>(UPDATE_CHARACTERS, {
      onCompleted, 
      refetchQueries: [
        'seeAllCharactrersByAdmin'
      ]
    })

    useEffect(() => {
      setIsModified(JSON.stringify(formData) !== JSON.stringify(initialFormData.current));
    }, [formData]);

    const handleChange = (e: { target: { name: any; value: any; }; }) => {
      const { name, value } = e.target;
      setFormData((prev: any) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e: { preventDefault: () => void; }) => {
      e.preventDefault();
    
      updateCharacterByAdmin({
        variables: {
          input: {
            characterId: formData.id, 
            tierId: +formData.tierId, 
            name: formData.name,
            nameEn: formData.nameEn,
            description: formData.description,
            descriptionEn: formData.description,
            baseHp: +formData.baseHp,
            baseSpeed: +formData.baseSpeed,
            baseDefense: +formData.baseDefense,
            baseAgility: +formData.baseAgility, 
          }
        }
      })
    };

    return (
      <form onSubmit={handleSubmit} className="w-full mt-12">
      <div className="grid grid-cols-2  grid-rows-1 gap-4">
        <div className="mb-4">
          <label className="block text-black font-bold text-xs font-display mb-2">Name(한글로 작성)</label>
          <input
            type="text"
            name="name"
            placeholder="캐릭터의 한글 이름을 작성하세요."
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
          />
       
        </div>
        <div className="mb-4">
        <label className="block text-black font-bold text-xs font-display mb-2">Name(영어로 작성)</label>
          <input
            type="text"
            name="nameEn"
            placeholder="캐릭터의 영문 이름을 작성하세요."
            value={formData.nameEn}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
          />
        </div>
        </div>
        <div className="mb-4">
          <label className="block text-black font-bold text-xs font-display mb-2">Description(영어로 작성)</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block text-black font-bold text-xs font-display mb-2">tier</label>
          <select
              name="tierId"
              value={formData.tierId}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
            >
              <option value={1}>common</option>
              <option value={2}>uncommon</option>
              <option value={3}>rare</option>
              <option value={4}>super rare</option>
              <option value={5}>epic</option>
              <option value={6}>super epic</option>
            </select>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-10">
        <div className="mb-4">
          <label className="block text-black font-bold text-xs font-display mb-2">Base HP  (캐릭터 체력)</label>
          <input
            type="text"
            name="baseHp"
            value={formData.baseHp}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block text-black font-bold text-xs font-display mb-2">Base Agility (캐릭터 민첩성) </label>
          <input
            type="text"
            name="baseAgility"
            value={formData.baseAgility}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block text-black font-bold text-xs font-display mb-2">Base Defense  (캐릭터 방어력)</label>
          <input
            type="text"
            name="baseDefense"
            value={formData.baseDefense}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
          />
        </div>
        <div className="mb-4">
          <label className="block text-black font-bold text-xs font-display mb-2">Base Speed  (캐릭터 이동속도)</label>
          <input
            type="text"
            name="baseSpeed"
            value={formData.baseSpeed}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
          />
        </div>
      </div>
        {/* Add more fields as needed */}
        <div className="flex justify-end">
          <button type="submit" className={`px-4 hover:opacity-60 py-2 bg-primary-green text-black font-display text-sm rounded ${!isModified || updating ? 'opacity-50 cursor-not-allowed' : ''} ${updating && 'items-center flex justify-center'}`} disabled={!isModified || updating}>
          {updating ? <SmallLoader height={24} width={24} /> : '수정'}
          </button>
        </div>
        {showSuccessAlert && (
        <Toast
          message="캐릭터를 정상적으로 수정했습니다."
          show={showSuccessAlert}
          duration={2000}
          onClose={() => setShowSuccessAlert(false)}
          type="success"
        />
      )}
      {showErrorAlert && (
        <Toast
          message="오류가 발생했습니다."
          show={showErrorAlert}
          duration={2000}
          onClose={() => setShowErrorAlert(false)}
          type="error"
        />
      )}
      </form>
    );
  };

  export default CharacterForm;
