import React, { useState, useEffect } from "react";
import {
  Card,
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import Logo from "@/assets/logos/logo_web.png";
import LogoFull from "@/assets/logos/wellxy_logo_black.png";
import {
  ChevronRightIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import {
  HomeIcon,
  ShoppingBagIcon,
  BoltIcon,
  HandRaisedIcon,
  StarIcon,
  LockClosedIcon, 
  LockOpenIcon,
  ChevronDoubleRightIcon,
  UsersIcon,
  ChevronDoubleLeftIcon,
  
} from "@heroicons/react/20/solid";
import { useMe } from "@/hooks/useMe";
import { motion } from "framer-motion";
import { AdminRole } from "@/__generated__/globalTypes";
import { useNavigate, useLocation } from "react-router-dom";

export function Sidebar() {
  const [open, setOpen] = useState<number>(0);
  const navigate = useNavigate();
     const location = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false); // 사이드바 접기 상태 추가
  const { data: meData } = useMe();

  const handleOpen = (value: number) => {
    setOpen(open === value ? 0 : value);
  };

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };



  useEffect(() => {
    // 경로가 변경될 때마다 실행됨
    const paths = [
      { value: 1, paths: ["/media/banner", "/media/notice", "/media/news"] },
      { value: 2, paths: ["/shop/items", "/shop/characters"] },
      { value: 3, paths: ["/games/squat", "/games/runner"] },
      { value: 5, paths: ["/users/active", "/users/inactive", "/users/blocked"] },
      { value: 4, paths: ["/super/admin-members"] },
    ];

    const currentPath = location.pathname;
    const matchingPath = paths.find(path => path.paths.includes(currentPath));

    if (matchingPath) {
      setOpen(matchingPath.value);
    }
  }, [location.pathname]);


  const renderAccordion = (value: number, icon: React.ReactNode, title: string, items: { name: string, path: string }[]) => {
    return (
      <Accordion
        open={open === value}
        icon={
          !isCollapsed && (
            open === value ? (
              <ChevronUpIcon strokeWidth={2.5} className="mx-auto  h-4 w-4 transition-transform" />
            ) : (
              <ChevronDownIcon strokeWidth={2.5} className="mx-auto h-4 w-4  transition-transform" />
            )
          )
        }
      >
        <ListItem className={`p-0 ${items.some(item => location.pathname === item.path) && !isCollapsed ? 'text-favorite-gray' : 'bg-white text-black'}`} selected={open === value}>
          <AccordionHeader onClick={() => handleOpen(value)} className="border-b-0 p-3  text-black hover:text-primary-green focus:text-gray ">
            <ListItemPrefix>{icon}</ListItemPrefix>
            {!isCollapsed && (
              <Typography className="mr-auto font-semibold font-sans">
                {title}
              </Typography>
            )}
          </AccordionHeader>
        </ListItem>
        <AccordionBody className="py-1">
          <List className="p-0">
            {items.map((item, index) => (
              <ListItem
                key={index}
                className={`font-sans ${location.pathname === item.path ? 'bg-black text-primary-green' : ''}`}
                onClick={() => navigate(item.path)}
              >
                <ListItemPrefix>
                  <ChevronRightIcon strokeWidth={3} className="h-3 w-5" />
                </ListItemPrefix>
                {!isCollapsed && item.name}
              </ListItem>
            ))}
          </List>
        </AccordionBody>
      </Accordion>
    );
  };




  return (
    <Card className={`h-[calc(100vh-2rem)] p-4 shadow-xl shadow-blue-gray-900/5 bg-[#f2f6f6] transition-width duration-300 ${isCollapsed ? "w-24" : " w-96"}`}>
      <div className="mb-2 flex items-center justify-between p-4">
        <img src={isCollapsed ? Logo : LogoFull} alt="logo" className={`z-0 object-contain ${isCollapsed ? "w-10 h-10" : "w-24 h-10"}`} />
        <button onClick={handleToggle} className="p-1 rounded-md hover:bg-gray-200">
          {isCollapsed ? <ChevronDoubleRightIcon className="h-6 w-6" /> : <ChevronDoubleLeftIcon className="h-6 w-6" />}
        </button>
      </div>
      <div className="flex flex-col w-full justify-between h-full">
        <List className={isCollapsed ? "w-16": "w-80"}>
          <ListItem className="text-black hover:text-primary-green" onClick={() => navigate('/')}>
            <ListItemPrefix>
              <HomeIcon className="h-5 w-5" />
            </ListItemPrefix>
            {!isCollapsed && (
              <Typography className="mr-auto font-semibold font-sans ">
                Home
              </Typography>
            )}
          </ListItem>
          {renderAccordion(3, <StarIcon className="h-5 w-5" />, "Games", [
            { name: "Squat game", path: "/games/squat" },
            { name: "Runner game", path: "/games/runner" }
          ])}
          {renderAccordion(5, <UsersIcon className="h-5 w-5" />, "Users", [
            { name: "Active Users", path: "/users/active" },
            { name: "Inactive Users", path: "/users/inactive" },
            { name: "Blocked Users", path: "/users/blocked" }
          ])}
          <hr className={isCollapsed ? "my-5 border-lightgray w-12 mr-6" : "my-5 border-lightgray"} />
          {renderAccordion(1, <BoltIcon className="h-5 w-5" />, "Media", [
            { name: "Banner", path: "/" },
            { name: "Notice", path: "/media/notice" },
            { name: "News (official)", path: "/media/news" }
          ])}
          {renderAccordion(2, <ShoppingBagIcon className="h-5 w-5" />, "Shop", [
            { name: "Items", path: "/shop/items" },
            { name: "Characters", path: "/shop/characters" }
          ])}
          {meData?.useAdmin?.adminRole === AdminRole.SUPER && renderAccordion(4, <HandRaisedIcon className="h-5 w-5" />, "SUPER Only", [
            { name: "Admin Members", path: "/super/admin-members" }
          ])}
          <hr className={isCollapsed ? "my-10 border-lightgray w-12 mr-6" : "my-10 border-lightgray"} />
        </List>
        <motion.div
          whileTap={{ scale: 0.97 }}
          onClick={()=> navigate('/my')}
          className={`flex flex-row cursor-pointer border-lightgray border mt-4 rounded-xl items-center p-3 hover:bg-black text-blue-gray-900 hover:text-primary-green ${isCollapsed ? "justify-center" : "justify-between"}` }
        >
          <div className="flex flex-row">
            <img src={`${meData?.useAdmin?.profileImageUrl}`} alt="profile-url" className={isCollapsed ? "w-5 h-5 rounded-full": "w-7 h-7 rounded-full"} />
            {!isCollapsed && <div className="font-sans text-lg ml-3">{meData?.useAdmin?.displayName}</div>}
          </div>
          {!isCollapsed && (location.pathname == '/my' ?  <LockOpenIcon className="h-5 w-5" /> : <LockClosedIcon className="h-5 w-5" />)}
        </motion.div>
      </div>
    </Card>
  );
}
