import React from 'react';

const InputField = React.memo(({ value, onChange, onBlur, placeholder }:any) => {
  return (
    <input
      type="text"
      spellCheck="false"
      autoComplete="off"
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      className="md:text-base mb-1 focus:outline-none bg-transparent focus:shadow-none rounded-xl focus:ring-0 ring-0 ring-transparent focus:border-green border-gray border text-black font-display w-full flex p-3 placeholder:text-gray"
      placeholder={placeholder}
    />
  );
});

export default InputField;
