import React from 'react'



export default function Team () {
    return (
        <div className="flex flex-col items-center justify-center font-display text-black">
            component is Ready
        </div>
    )
}