import { adminActiveControl, adminActiveControlVariables } from "@/__generated__/adminActiveControl";
import { ADMIN_ACTIVE_CONTROL } from "@/apis/mutation";
import { useMutation } from "@apollo/client";
import React, { Fragment, useEffect, useRef, useState } from "react";
import Toast from "@/components/toasters/toast";
import { SmallLoader } from "@/components/loader/small-loader";
import { AdminRole } from "@/__generated__/globalTypes";




const UpdateAdminForm = ({ admin, closeModal }: any) => {
    const [formData, setFormData] = useState(admin);
    const [isModified, setIsModified] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const initialFormData = useRef(admin);


    const onCompleted = async (data: adminActiveControl) => {
        const {adminActiveControl: {ok, error}} = data;
        if(ok) {
          setShowSuccessAlert(true);
              setTimeout(() => {
                  setShowSuccessAlert(false);
                  closeModal();
              }, 2000);
        } else {
          setShowErrorAlert(true);
          setTimeout(() => {
              setShowErrorAlert(false);
          }, 2000);
  
        }
      }


    const [adminActiveControl, {loading: updating}] = useMutation<adminActiveControl, adminActiveControlVariables>(ADMIN_ACTIVE_CONTROL, {
      onCompleted, 
      refetchQueries: [
        'getAllAdmins'
      ]
    })


    useEffect(() => {
        setIsModified(JSON.stringify(formData) !== JSON.stringify(initialFormData.current));
      }, [formData]);



    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormData((prev: any) => ({ ...prev, [name]: value }));
      };
  
      const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
       console.log(formData)
       adminActiveControl({
        variables: {
            input: {
                adminId: formData.id, 
                name: formData.name,
                displayName: formData.displayName,
                adminRole: formData.adminRole
            }
        }
    })

      }


      return (
        <form onSubmit={handleSubmit} className="w-full mt-12">
        <div className="grid grid-cols-2  grid-rows-1 gap-4">
          <div className="mb-4">
          <label className="block text-black font-bold text-xs font-display mb-2">displayName (표시 성명)</label>
            <input
              type="text"
              name="displayName"
              placeholder="멤버 전체에게 출력되는 성명을 작성하세요."
              value={formData.displayName}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
            />
         
          </div>
          <div className="mb-4">
          <label className="block text-black font-bold text-xs font-display mb-2">name(실명)</label>
            <input
              type="text"
              name="name"
              placeholder="실명을 작성하세요."
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
            />
          </div>
          </div>
          <div className="mb-4">
            <label className="block text-black font-bold text-xs font-display mb-2">ROLE</label>
            <select
                name="adminRole"
                value={formData.adminRole}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
              >
                <option value={AdminRole.MANAGER}>MANAGER</option>
                <option value={AdminRole.MEMBER}>MEMBER</option>
                <option value={AdminRole.SUPER}>SUPER</option>
              </select>
          </div>
          <div className="flex justify-between items-center mt-24">
             <button  onClick={()=>{}} 
               className={`px-4 hover:opacity-60 py-2 bg-mainorange text-white font-display text-sm rounded ${updating ? 'opacity-50 cursor-not-allowed' : ''}`} disabled={updating}>
            {updating ? <SmallLoader height={24} width={24} /> : '삭제'}
            </button> 
            <button type="submit" className={`px-4 hover:opacity-60 py-2 bg-primary-green text-black font-display text-sm rounded ${!isModified || updating ? 'opacity-50 cursor-not-allowed' : ''} ${updating && 'items-center flex justify-center'}`} disabled={!isModified || updating}>
            {updating ? <SmallLoader height={24} width={24} /> : '수정'}
            </button>
          </div>
          {showSuccessAlert && (
          <Toast
            message="어드민 멤버를 정상적으로 수정했습니다."
            show={showSuccessAlert}
            duration={2000}
            onClose={() => setShowSuccessAlert(false)}
            type="success"
          />
        )}
        {showErrorAlert && (
          <Toast
            message="오류가 발생했습니다."
            show={showErrorAlert}
            duration={2000}
            onClose={() => setShowErrorAlert(false)}
            type="error"
          />
        )}
        </form>
      );
    };
  


    export default UpdateAdminForm;