import { useMutation, gql } from '@apollo/client';
import React from 'react';
import {BrowserRouter, Route, Routes, } from 'react-router-dom';
import Layout from '../components/layouts/layout';
import { Login } from '../pages/common/login';
import { NotFound } from '../pages/common/notfound';



// Switch -> Routes로 변경됨. 

export const AuthRouter = () => {
   return (
   <BrowserRouter>
       <Routes>
       <Route path="/" element={<Login />} />
       <Route path="*" element={<NotFound />} />
       </Routes>
   </BrowserRouter>
   )
}
