import Layout from '@/components/layouts/layout'
import React, {useEffect, useRef, useState} from 'react'
import { 
    Breadcrumbs,
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
} from "@material-tailwind/react";
import {
    ArrowDownTrayIcon,
    MagnifyingGlassIcon,
    PlusCircleIcon,
    PencilSquareIcon
  } from "@heroicons/react/20/solid";
  import {loadAuth2WithProps} from 'gapi-script';
  import DatePicker, { CalendarContainer } from "react-datepicker";
  import "react-datepicker/dist/react-datepicker.css"; 
import { useQuery } from '@apollo/client';
import MyModal from '@/components/Modal/my-modal';
import { GET_ALL_ADMINS } from '@/apis/query';
import { getAllAdmins } from '@/__generated__/getAllAdmins';
import NewAdminAddForm from '@/components/members/new-form';
import { formatDate } from '@/utils/time-converter';
import UpdateAdminForm from '@/components/members/update-form';


const MEMBER_TABLE_HEAD = [
    {
      head: "id",
      customeStyle: "!text-left",
    },
    {
      head: "이름",
      customeStyle: "text-center",
    },
    {
      head: "이메일(로그인아이디)",
      customeStyle: "text-center",
    },
    {
      head: "표시 이름",
      customeStyle: "text-center",
    },
    {
        head: "권한",
        customeStyle: "text-center",
      },
    {
      head: "마지막 로그인",
      customeStyle: "text-center",
    },
    {
        head: "가입일자",
        customeStyle: "text-center",
      },
      {
        head: "Edit",
        customeStyle: "text-center !bg-[#f2f6f6]",
      },
  ];



  export default function Members () {
    const {data, loading}=useQuery<getAllAdmins>(GET_ALL_ADMINS);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState<any>(null);



      //새로운 어드민 등록
      const [showAdminForm, setShowAdminForm] = useState(false);
      const [adminToEdit, setAdminToEdit] = useState<any>(null);



        
    const openNewAdminForm = () => {
        setAdminToEdit({
            name: "",
            displayName:"",
            username:"",
            password:""
        });
        setShowAdminForm(true);
      };


      const closeAdminForm = () =>{
        setShowAdminForm(false);
        setAdminToEdit(null);
      }


      const openModal = (admin:any) => {
        setSelectedAdmin(admin);
        setModalOpen(true);
      };
    
    

       
    const closeModal = () => {
        setModalOpen(false);
        setSelectedAdmin(null);
      };


      return (
        <Layout hasNavbar={true} isFooter={false} isSidebar={true}>
         <div className="flex flex-col w-full min-h-screen overflow-y-scroll bg-white p-10">
            <div className="font-body text-4xl mb-3">
            Members
            </div>
            <Card className="h-full w-full mt-6">
            <CardHeader floated={false} shadow={false} className="rounded-none">
                    <div className="mb-4 flex flex-col justify-between gap-8 md:flex-row md:items-center">
                    <div>
                        <Typography variant="h5" color="black">
                        Admin Member
                        </Typography>
                        <Typography color="gray" className="mt-1 font-display font-normal">
                        새로운 어드민 멤버를 등록, 수정할 수 있는 페이지입니다.
                        </Typography>
                    </div>
                    <Button className="flex items-center gap-3 text-black" size="sm" onClick={() => openNewAdminForm()}>
                    <PlusCircleIcon strokeWidth={2} className="h-4 w-4 text-black" /> NEW 
                    </Button>
                    </div>
                </CardHeader>
                <CardBody className="overflow-scroll !px-0 py-2">
      <table className="w-full min-w-max table-auto mt-12">
        <thead className="bg-[#f2f6f6]">
          <tr>
            {MEMBER_TABLE_HEAD.map(({ head, customeStyle }) => (
              <th
                key={head}
                className={`border-b border-gray-300 bg-[#f2f6f6] !p-4 pb-4 ${customeStyle}`}
              >
                <Typography
                  color="black"
                  variant="small"
                  className="!font-bold"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="mt-3">
        {data?.getAllAdmins?.admins?.map((c, index) => {
              const isLast = data?.getAllAdmins.admins
              ? index === data.getAllAdmins.admins.length - 1
              : false;
            const classes = isLast
              ? "!p-4 "
              : "!p-4 border-b border-lightgray";
            let formattedDate = formatDate(c.lastSignedAt);

            let createdFormattedDate = formatDate(c.createdDate);
              
            return (
                <tr key={c.id} className="h-14">
                                <td className={classes}>
                                <div className="block items-center justify-center text-center">
                                    <Typography
                                    variant="small"
                                    className="!font-normal text-black"
                                    >
                                    {c.id}
                                    </Typography>
                                </div>
                                </td>
                                <td className={classes}>
                                <div className="block items-center justify-center text-center">
                                    <Typography
                                    variant="small"
                                    className="!font-normal text-black"
                                    >
                                    {c.name}
                                    </Typography>
                                </div>
                                </td>
                                <td className={classes}>
                                <div className="block items-center justify-center text-center">
                                    <Typography
                                    variant="small"
                                    className="!font-normal text-black"
                                    >
                                    {c.username}
                                    </Typography>
                                </div>
                                </td>
                                <td className={classes}>
                                <div className="block items-center justify-center text-center">
                                    <Typography
                                    variant="small"
                                    className="!font-normal text-black"
                                    >
                                    {c.displayName}
                                    </Typography>
                                </div>
                                </td>
                                <td className={classes}>
                                <div className="block items-center justify-center text-center">
                                    <Typography
                                    variant="small"
                                    className="!font-normal text-black"
                                    >
                                    {c.adminRole}
                                    </Typography>
                                </div>
                                </td>
                                <td className={classes}>
                                <div className="block items-center justify-center text-center">
                                    <Typography
                                    variant="small"
                                    className="!font-normal text-black"
                                    >
                                    {formattedDate}
                                    </Typography>
                                </div>
                                </td>
                                <td className={classes}>
                                <div className="block items-center justify-center text-center">
                                    <Typography
                                    variant="small"
                                    className="!font-normal text-black"
                                    >
                                    {createdFormattedDate}
                                    </Typography>
                                </div>
                                </td>
                                <td className={classes}>
                                <div className="block justify-center text-center">
                                    <IconButton variant="text" size="sm" onClick={() => openModal(c)}>
                                    <PencilSquareIcon className="h-5 w-5 text-black ml-2 hover:text-gray" />
                                    </IconButton>
                                </div>
                                </td>
               </tr>
            )
        })}
        </tbody>
            </table>
            {
                showAdminForm && (
                    <MyModal open={showAdminForm} close={closeAdminForm} header="어드민 신규 등록">
                        <NewAdminAddForm admin={adminToEdit} closeModal={closeAdminForm} />
                    </MyModal>
                )
            }
                 {selectedAdmin && (
                    <MyModal open={modalOpen} close={closeModal} header={`수정 중인 멤버: ${selectedAdmin?.name}`}>
                    <UpdateAdminForm admin={selectedAdmin} closeModal={closeModal} />
                    </MyModal>
      )}
            </CardBody>
            </Card>
            </div>
        </Layout>

      )

}