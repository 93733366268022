import Layout from '@/components/layouts/layout'
import React from 'react'
import { 
    Breadcrumbs,
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    Chip,
    CardFooter,
    Avatar,
    IconButton,
    Tooltip,
    Input,
} from "@material-tailwind/react";
import {
    ArrowDownTrayIcon,
    MagnifyingGlassIcon,
    PencilIcon,
  } from "@heroicons/react/20/solid";



   
const TABLE_HEAD = ["Transaction", "Amount", "Date", "Status", "Account", ""];
 

export default function Shop() {
    return (
        <Layout hasNavbar={true} isFooter={false} isSidebar={true}>
        <div className="flex flex-col w-full min-h-screen overflow-y-scroll bg-white p-10">
            <div className="font-body text-4xl mb-3">
                SHOP
            </div>

            <Breadcrumbs className='p-1 hover:text-mainpurple transition-all'>
                 <a href="#" className="font-sans opacity-60 hover:text-mainpurple">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                >
                                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                                </svg>
                            </a>
                    <a href="#" className="font-sans opacity-60 hover:text-mainpurple">
                        Shop
                    </a>
                    <a href="#" className="font-sans hover:text-mainpurple font-semibold text-secondarypurple">Items</a>
                    </Breadcrumbs>
        </div>
        </Layout>
    )
}