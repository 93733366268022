import React, { Fragment, useEffect, useRef, useState } from "react";
import { useMutation } from "@apollo/client";
import Toast from "@/components/toasters/toast";
import { SmallLoader } from "@/components/loader/small-loader";
import { ADMIN_INFO_EDIT } from "@/apis/mutation";
import { editMyAdminInfo, editMyAdminInfoVariables } from "@/__generated__/editMyAdminInfo";




const ProfileSettingForm = ({meData} : any) => {
    const [formData, setFormData] = useState(meData.useAdmin);
    const [isModified, setIsModified] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const initialFormData = useRef(meData);



    console.log(formData)

    const onCompleted = async (data: editMyAdminInfo) => {
        const {editMyAdminInfo: {ok, error}} = data;
        if(ok) {
            setShowSuccessAlert(true);
            setTimeout(() => {
                setShowSuccessAlert(false);
              }, 2000);
        } else {
            setShowErrorAlert(true);
            setTimeout(() => {
                setShowErrorAlert(false);
            }, 2000);
        }
    }


    const [editMyAdminInfo] = useMutation<editMyAdminInfo, editMyAdminInfoVariables>(ADMIN_INFO_EDIT, {
        onCompleted, 
        refetchQueries: [
            'useAdmin'
        ]
    })


    useEffect(() => {
        setIsModified(JSON.stringify(formData) !== JSON.stringify(initialFormData.current));
      }, [formData]);





    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormData((prev: any) => ({ ...prev, [name]: value }));
      };


      const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            const reader = new FileReader();
            
            reader.onloadend = () => {
                setFormData((prev: any) => ({
                    ...prev,
                    profileImageUrl: reader.result as string
                }));
            }
            
            reader.readAsDataURL(file);
        }
    };





      const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
       console.log(formData)

      }



      return (
        <form onSubmit={handleSubmit} className="w-full mb-6 select-none">

<span className="inline-block text-black font-bold text-sm font-display my-4 bg-primary-green rounded-lg py-1 px-3 w-auto">{formData.username}</span>

<div className="mb-4">
        <label className="block text-dark font-semibold text-sm font-display mb-4">내 프로필 이미지</label>
        {formData.profileImageUrl && (
            <img
                src={formData.profileImageUrl}
                alt="Profile"
                className="w-24 h-24 rounded-full object-cover mb-2"
            />
        )}
        <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
        />
            </div>
          
        
  
          <div className="grid grid-cols-2  grid-rows-1 gap-4">
          <div className="mb-4">
          <label className="block text-black font-bold text-xs font-display mb-2">displayName (표시 성명)</label>
            <input
              type="text"
              name="displayName"
              placeholder="멤버 전체에게 출력되는 성명을 작성하세요."
              value={formData.displayName}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
            />
         
          </div>
          <div className="mb-4">
          <label className="block text-black font-bold text-xs font-display mb-2">name(실명)</label>
            <input
              type="text"
              name="name"
              placeholder="실명을 작성하세요."
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
            />
          </div>
          </div>

        </form>
      )

}

export default ProfileSettingForm;