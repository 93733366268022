import React, { PropsWithChildren } from 'react';
import './styles.css';

interface ActionButtonProps
   extends PropsWithChildren,
      Omit<
         React.DetailedHTMLProps<
            React.ButtonHTMLAttributes<HTMLButtonElement>,
            HTMLButtonElement
         >,
         'className'
      > {
         tooltip?: string; // 툴팁 내용을 받을 속성 추가
      }

export const ActionButton: React.FC<ActionButtonProps> = ({
   children,
   tooltip,
   ...props
}) => {
   return (
      <button className="button-45" {...props}>
         {children}
         {tooltip && <span className="tooltip">{tooltip}</span>}
      </button>
   );
};
