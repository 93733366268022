import { useAdmin } from '@/__generated__/useAdmin'
import Layout from '@/components/layouts/layout';
import { ADMIN_ME_QUERY, useMe } from '@/hooks/useMe'
import { greetings } from '@/utils/greetings';
import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react';
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import ProfileSettingForm from '@/components/my/profile-setting';
import Team from '@/components/my/team';
import ChangePassword from '@/components/my/password';
import ChangeImage from '@/components/my/image-setting';







export default function MyInfo() {
    const {data} = useMe();


const MyInfoData = [
    {
        label : '기본정보', 
        value: 'profile', 
        component: <ProfileSettingForm meData={data} />
    }, 
    {
        label : '비밀번호수정', 
        value: 'password', 
        component: <ChangePassword />
    },
    {
        label : '프로필 이미지 변경', 
        value: 'changeImage', 
        component: <ChangeImage />
    },
    {
        label : '팀', 
        value: 'team', 
        component: <Team />
    },
]


  const [greeting, setGreeting] = useState('');

  useEffect(() => {
    const randomGreeting = greetings[Math.floor(Math.random() * greetings.length)];
    setGreeting(randomGreeting);
  }, []);
    return (
        <Layout hasNavbar={true} isFooter={false} isSidebar={true}>
            <div className="flex flex-col w-full min-h-screen overflow-y-scroll bg-white p-10">
                <div className="font-body text-4xl mb-3 mt-4">
                    Settings
                </div>
                <div className="font-display text-lg mt-2 font-thin text-gray">
                    {greeting}, {data?.useAdmin.name?.slice(1)}님!
                </div>
                <div className="grid gap-4 w-full mt-4">
                        <Tabs value="profile" className="" orientation="vertical">
                            <TabsHeader
                                className="bg-transparent flex flex-col gap-4 w-full px-8 mr-4"
                                indicatorProps={{
                                    className: "bg-lightgray px-4 shadow-none font-display text-base !text-black",
                                }}
                            >
                                {MyInfoData.map(({ label, value }) => (
                                    <Tab key={value} value={value}>
                                        {label}
                                    </Tab>
                                ))}
                            </TabsHeader>
                            <TabsBody
                                className="flex-1 pl-4 border-l border-gray h-full"
                            >
                                {MyInfoData.map(({ value, component }) => (
                                    <TabPanel key={value} value={value} className="py-0">
                                        {component}
                                    </TabPanel>
                                ))}
                            </TabsBody>
                        </Tabs>
                </div>
            </div>
        </Layout>
    )
}