import React, {useEffect, useState} from 'react';
import { Alert } from "@material-tailwind/react";
import { CheckCircleIcon, ExclamationTriangleIcon } from '@heroicons/react/20/solid';



interface CustomToastProps {
    message: string;
    show: boolean;
    duration?: number;
    onClose?: () => void;
    type?: 'success' | 'error';
  }




const Toast:  React.FC<CustomToastProps>  = ({ message, show, duration, onClose, type = 'success' }) => {
  const [visible, setVisible] = useState(show);

  useEffect(() => {
    if (show) {
      setVisible(true);
      const timer = setTimeout(() => {
        setVisible(false);
        if (onClose) onClose();
      }, duration);
      return () => clearTimeout(timer);
    }
  }, [show, duration, onClose]);

  if (!visible) return null;

  const isError = type === 'error';

  return (
    <Alert
      icon={isError ? <ExclamationTriangleIcon /> : <CheckCircleIcon />}
      className={`rounded-none border-l-4 ${
        isError ? 'border-[#FF5100] bg-[#FF5100] bg-opacity-20 text-[#FF5100]' : 'border-[#bcff4f] bg-[#f2f6f6] text-primary-green'
      } font-medium`}
      variant="filled"
      animate={{ mount: { y: 0 }, unmount: { y: 100 } }}
    >
      {message}
    </Alert>
  );
};

export default Toast;