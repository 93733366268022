import { authTokenVar, BASE_URL } from '@/utils/apollo';
import axios from 'axios';


export const uploadImageToServer = async (file: File): Promise<string> => {
  const jwtToken = authTokenVar(); // JWT 토큰 가져오기
  const formData = new FormData();
  formData.append('file', file);

  try {
    const response = await axios.post(`${BASE_URL}/upload/news/thumbnail`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${jwtToken}`
        },
    });

    console.log('url:', response.data)

    return response.data.uri;

} catch (error) {
    console.error('Error uploading image:', error);
  return ''
}



};