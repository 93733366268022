import React from 'react';
import Layout from '@/components/layouts/layout';
import { Breadcrumbs } from '@material-tailwind/react';
import {motion} from 'framer-motion';
import { createPortal } from "react-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form"
import {LexicalComposer} from '@lexical/react/LexicalComposer'
import { $generateHtmlFromNodes } from '@lexical/html';
import InputField from '@/components/common/input';
import { MyEditor } from '@/components/editor/writer';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup"
import { NewsCategoryType } from '@/__generated__/globalTypes';
import SelectField from '@/components/common/select';
import { uploadImageToServer } from '@/components/editor/nodes/image-uploader';
import { LexicalEditor } from '@/components/LexicalEditor';
import { BASE_URL, authTokenVar } from '@/utils/apollo';
import axios from 'axios';






  const options = Object.values(NewsCategoryType).map((value) => ({ value, label: value }));



export default function NewsAdd () {
    const editorRef: any = useRef();
    const [thumbnail, setThumbnail] = useState<string | null>(null);
    const [htmlContent, setHtmlContent] = useState<string>('');
    const [imageFiles, setImageFiles] = useState<File[]>([]);
 
    const schema = yup
    .object({
      title: yup.string().required(),
      newsType: yup.string().oneOf([NewsCategoryType.ANNOUNCEMENT, NewsCategoryType.COMMUNITY_AND_UX, NewsCategoryType.ECONOMIC_MODEL_AND_REWARDS, NewsCategoryType.EVENT, NewsCategoryType.GAME_PLAY_GUIDE, NewsCategoryType.OTHER, NewsCategoryType.TECH_INSIGHT, NewsCategoryType.TRENDS, NewsCategoryType.UPDATES_AND_ROADMAP]).required(),
      content: yup.string().required(), 
      editor: yup.string().required(), 
      subTitle:yup.string().required(), 
      thumbnail: yup.string().required('Thumbnail is required')
    })
    .required();
    const {
        register,
        handleSubmit,
        watch,
        control, 
        getValues,
        setValue,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(schema)
      });




   const handleOnSubmit = async () => {
    // 이미지 파일 업로드
    const imageUploadPromises = imageFiles.map(file => uploadImageToServer(file));
    const imageUrls = await Promise.all(imageUploadPromises);

    // HTML 컨텐츠 업데이트 (이미지 URL을 포함하도록)
    let updatedHtmlContent = htmlContent;
    imageUrls.forEach((url, index) => {
       updatedHtmlContent = updatedHtmlContent.replace(`blob:http://localhost:3000/${imageFiles[index].name}`, url);
    });

    // 서버로 전송
    try {
       const response = await axios.post(`${BASE_URL}/submit`, { html: updatedHtmlContent }, {
          headers: {
             'Authorization': `Bearer ${authTokenVar()}`
          }
       });
       console.log('Server response:', response.data);
    } catch (error) {
       console.error('Error submitting HTML:', error);
    }
 };


 const handleClick = (editor: any) => {
    editor.update(() => {
      const editorState = editor.getEditorState();
      const jsonString = JSON.stringify(editorState);
      console.log('jsonString', jsonString);
  
      const htmlString = $generateHtmlFromNodes(editor, null);
      console.log('htmlString', htmlString);
    
    });
  };




 
      
      
      const onSubmit = async (data : any) => {      
        const imageUploadPromises = imageFiles.map(file => uploadImageToServer(file));
        const imageUrls = await Promise.all(imageUploadPromises);

        editorRef?.current?.update(() => {
            const editorState = editorRef.current.getEditorState();
            const content = JSON.stringify(editorState, null, 2);
            const htmlString = $generateHtmlFromNodes(editorRef.current, editorState);
            console.log('JSON content:', content);
            console.log('HTML content:', htmlString);
            data.content = content;
            console.log(data);
            // 서버로 데이터를 전송하거나 필요한 후속 작업 수행
        });


        let updatedHtmlContent = htmlContent;
    
        imageUrls.forEach((url, index) => {
            updatedHtmlContent = updatedHtmlContent.replace(`blob:http://localhost:3000/${imageFiles[index].name}`, url);
        });

      }

      const handleThumbnailChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const uploadedImageUrl = await uploadImageToServer(file);
            if (uploadedImageUrl) {
                setThumbnail(uploadedImageUrl);
                setValue('thumbnail', uploadedImageUrl);
            }
        }
    };



    return (
        <Layout hasNavbar={true} isFooter={false} isSidebar={true}>
        <div className="flex flex-col w-full h-screen overflow-y-scroll bg-white p-10">
            <div className="font-body text-4xl mb-3">
            Write a News Feed! 
            </div>
            <div className="flex flex-row justify-between w-full items-center">
            <Breadcrumbs className='font-sans p-1'>
                 <a href="/" className="opacity-60 hover:text-green font-sans">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                >
                                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                                </svg>
                            </a>
                    <a href="#" className="opacity-60">
                        Media
                    </a>
                    <a href="/media/news"  className="opacity-60">News</a>
                    <a href="/media/news/create">Add</a>
                    </Breadcrumbs>
                    <motion.button 
                    whileHover={{opacity: 0.6}}
                    whileTap={{scale:0.96}}
                    onSubmit={onSubmit}
                    onClick={onSubmit}
                    type="submit"
                    className={`flex flex-row px-3 py-2 bg-primary-green rounded-lg text-black`}>
                 <div className="text-base font-bold  text-black font-body">Upload</div>
                </motion.button>
            </div>
            <form className="max-w-screen-xl w-full flex flex-col mt-12 mx-auto" onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col">
                                        <Controller 
                                            name="title"
                                            control={control}
                                            rules={{
                                                required: true,
                                                
                                            }}
                                            render={({field: {onChange, onBlur, value}}) => (
                                                <InputField 
                                                value={value}
                                                onChange={(e: Event) => onChange(e)}
                                                onBlur={onBlur}
                                                placeholder='제목을 입력하세요.(영문)'
                                              />)}
                                        />
                                        <div className="mb-4 text-sm "><span className={watch('title')?.length > 50 ? 'text-mainorange' : 'text-green'}>{watch('title')?.length>0 ? watch('title')?.length : 0}</span>/50</div>

                                    <Controller 
                                            name="subTitle"
                                            control={control}
                                            rules={{
                                                required: true,
                                                
                                            }}
                                            render={({field: {onChange, onBlur, value}}) => (
                                                <InputField 
                                                value={value}
                                                onChange={(e: Event) => onChange(e)}
                                                onBlur={onBlur}
                                                placeholder='부제목을 입력하세요.'
                                              />)}
                                        />
                                           <div className="mb-4 text-sm "><span className={watch('subTitle')?.length > 100 ? 'text-mainorange' : 'text-green'}>{watch('subTitle')?.length>0 ? watch('subTitle')?.length : 0}</span>/100</div>
                                         <div className='flex flex-row items-center w-full justify-between'>
                                            <div className="flex flex-col mb-3 w-1/2">
                                            <Controller 
                                            name="editor"
                                            control={control}
                                            rules={{
                                                required: true,
                                            }}
                                            render={({field: {onChange, onBlur, value}}) => (
                                                <InputField 
                                                value={value}
                                                onChange={(e: Event) => onChange(e)}
                                                onBlur={onBlur}
                                                placeholder='작성자를 입력하세요.(영문)'
                                              />)}
                                        />
                                            <div className="mb-4 text-sm ">
                                                <span className="text-mainorange">{errors?.editor && errors?.editor.message}</span>
                                            </div>

                                            </div>
                               
                                        <div className="flex flex-col mb-3 ml-3 w-1/2">
                                        <Controller
                                                name="newsType"
                                                control={control}
                                                rules={{
                                                    required: true,
                                                }}
                                                render={({ field: { onChange, onBlur, value } }) => (
                                                    <SelectField
                                                    value={value}
                                                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => onChange(e.target.value)}
                                                    onBlur={onBlur}
                                                    options={options}
                                                    />
                                                )}
                                                />
                                              <div className="mb-4 text-sm ">
                                                <span className="text-mainorange">{errors?.newsType && errors?.newsType.message}</span>
                                            </div>
                                            </div>
                                         </div>
                                         <div className="mt-4 flex flex-row items-center justify-between ">
                                         {thumbnail ?  (
                              <div className="flex flex-col">
                                   <span className="font-sans font-light text-sm text-gray mb-3 ">preview</span>
                                 <img src={thumbnail as string} alt="Thumbnail Preview" className="w-80 h-36 object-contain bg-lightgray" />
                                                            </div>
                            ): (
                                <div className="flex flex-col">
                                     <span className="font-sans font-light text-sm text-mainorange mb-3 ">썸네일을 등록하세요.</span>
                                   <div className="w-80 h-36 object-contain bg-lightgray" />
                                                              </div>
                              )}
                            {errors.thumbnail && (
                                <p className="text-red-500 text-sm mt-1">{errors.thumbnail.message}</p>
                            )}
                                         <button
                                    className="bg-green w-auto h-10 px-3 py-1 rounded-xl text-white font-display"
                                    onClick={() => document.getElementById('thumbnail')?.click()}
                                >
                               썸네일 업로드
                            </button>
                            <input
                                  style={{ display: 'none' }}
                                id="thumbnail"
                                type="file"
                                accept="image/*"
                                onChange={handleThumbnailChange}
                                className="mt-2"
                            />
                           
                        </div>
                                    </div>
                                    <div className="flex flex-col overflow-y-scroll">
                                    <LexicalEditor 
                                           onContentChange={setHtmlContent}
                                           onImageFilesChange={setImageFiles}
                                    />
                                    </div>
                        </form>
                    </div>
        </Layout>
    )
}