import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import '@/styles/style.css';
import reportWebVitals from './reportWebVitals';
import { HelmetProvider } from 'react-helmet-async';
import { ApolloProvider } from '@apollo/client';
import { client } from './utils/apollo';
import { ThemeProvider } from "@material-tailwind/react";
import { theme } from './utils/theme';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    	<HelmetProvider>
					<ApolloProvider client={client}>
            <ThemeProvider value={theme}>
              <App />
            </ThemeProvider>
          </ApolloProvider>
    </HelmetProvider>
  </React.StrictMode>
);

reportWebVitals();
