import Layout from '@/components/layouts/layout';
import { Breadcrumbs } from '@material-tailwind/react';
import React from 'react';
import {motion} from 'framer-motion';
import {
    ArrowDownTrayIcon,
    MagnifyingGlassIcon,
    PencilSquareIcon,
  } from "@heroicons/react/20/solid";
  import { useNavigate } from 'react-router-dom';


export default function NewsMain () {
    const navigate = useNavigate();
    return (
        <Layout hasNavbar={true} isFooter={false} isSidebar={true}>
        <div className="flex flex-col w-full min-h-screen overflow-y-scroll bg-white p-10">
            <div className="font-body text-4xl mb-3">
                News Feed
            </div>
            <div className="flex flex-row justify-between w-full items-center">
            <Breadcrumbs className='font-manrope p-1'>
                 <a href="/" className="opacity-60 hover:text-green font-manrope">
                                <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-4 w-4"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                >
                                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                                </svg>
                            </a>
                    <a href="#" className="opacity-60">
                        Media
                    </a>
                    <a href="/media/news">News</a>
                    </Breadcrumbs>
                <motion.button 
                    whileHover={{opacity: 0.6}}
                    whileTap={{scale:0.96}}
                    onClick={()=> navigate('/media/news/create')}
                    className="flex flex-row px-3 py-2 bg-primary-green rounded-lg text-black">
                    <PencilSquareIcon className="text-black w-4 h-4" />
                 <div className="ml-2 text-sm text-black font-body">+ NEW</div>
                </motion.button>

            </div>
            
        </div>
        </Layout>
    )
}