import { useMutation, gql } from "@apollo/client";
import React, {useEffect} from "react";
import {motion} from 'framer-motion';
import { Helmet } from "react-helmet-async";
import {useForm, Controller} from 'react-hook-form';
import LogoFull from '@/assets/logos/wellxy_logo_black.png';
import { authTokenVar, isLoggedInVar } from "@/utils/apollo";
import { LOCALSTORAGE_TOKEN } from "@/utils/constants";
import { loginAdmin, loginAdminVariables } from "@/__generated__/loginAdmin";
import { SmallLoader } from "@/components/loader/small-loader";
import Layout from "@/components/layouts/layout";



interface Iform {
	username: string;
	password: string;
}



const ADMIN_LOGIN = gql`
    mutation loginAdmin($username: String!, $password: String!) {
        loginAdmin(username: $username, password: $password) { 
            ok
            error
            token
        }
    }
`;



export const Login = () => {
    const {
		register,
		handleSubmit,
		getValues,
		watch,
        setValue,
        control,
		reset,
		formState: { errors },
	} = useForm<Iform>({ mode: "onChange" });





/*     useEffect(() => {
        const initializeGoogleSignIn = () => {
            window?.google?.accounts?.id?.initialize({
                client_id: '288687732292-k0mnfkg9uo1fm8dh1n18dc5hglfrb1de.apps.googleusercontent.com',
                callback: handleCredentialResponse,
                auto_select: true, // Automatically select the account if possible
                cancel_on_tap_outside: false // Do not cancel on tap outside
            });


            window.google?.accounts?.id?.prompt((notification: any) => {
                if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
                    console.log("One Tap not displayed or skipped");
                }
            });
        };

        const handleCredentialResponse = (response: any) => {
            const idToken = response.credential;
            console.log('Google IdToken:', idToken);
            // 여기서 idToken을 서버로 전송하거나 다른 작업을 수행할 수 있습니다.
        };

        initializeGoogleSignIn();
    }, []); */



	const onCompleted = (data: loginAdmin) => {
		const {
			loginAdmin: { ok, token, error },
		} = data;
		if (ok && token) {
            localStorage.setItem(LOCALSTORAGE_TOKEN, token);
            authTokenVar(token);
            isLoggedInVar(true);
		}
	};

	 
	const [loginAdmin, {loading, data: loginData}] = useMutation<loginAdmin, loginAdminVariables>(ADMIN_LOGIN, {onCompleted});

    const onSubmit = () => {
		if (!loading) {
			const { username, password } = getValues();
            console.log(username, password)
			loginAdmin({
				variables: {
                    username, 
                    password,
				},
			});
		}
	};

    const validatePassword = (value:string) => {
        // 정규표현식을 사용하여 영문자와 숫자의 혼합, 최소 길이 8자인지 검사
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/;
    return passwordRegex.test(value) || "영문자와 숫자를 혼합하여 8자 이상으로 입력해주세요. 특수문자(@$!%*#?&) 입력가능";
};
      

    return (
		<>
          <Helmet>
                <title>Wellxy | 로그인</title>
            </Helmet>
            <Layout hasNavbar={false} isFooter={false} >
                <div className="flex flex-row w-screen items-center justify-between">
                <div className="flex flex-col w-full p-10"> 
                <img src={LogoFull} alt="logo" className="w-1/4" />
                <form className="w-full flex flex-col items-start" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mt-24 w-full flex flex-col">
                    <Controller 
                        name="username"
                        control={control}
                        rules={{
                            required: true,
                            minLength: 3,
                            maxLength: 32,
                        }}
                        render={({field: {onChange, onBlur, value}}) => (
                            <input 
                            {...register("username", {
                            required: true, 
                                maxLength: {
                                    value: 32, 
                                    message: "이메일은 32자를 초과할 수 없습니다"
                                },
                                pattern: {
                                    value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                                    message: "유효한 이메일 주소를 입력하세요" 
                                }
                            })}
                            name="username"
                            type="email"
                            autoComplete="email"
                            autoCapitalize="none"
                            placeholder="이메일을 입력하세요"
                            className="w-full rounded-2xl flex border border-gray placeholder-gray px-5 py-3 font-display selection:bg-white focus:outline-none focus:border-primary-green  focus:border-2 focus:shadow-none text-lg font-light text-dark"
                            onChange={(e) => {
                            setValue('username', e.target.value);
                            onChange(e)
                        }}
                        value={value}
                        onBlur={onBlur}
                       />
                    )}
                    />
                   {errors.username && (
							<span className="font-display text-left mt-3 ml-3 text-xs text-red  ">
					      {errors.username.message}
							</span>
						)}
                    </div>
                    <div className="mt-24 w-full flex flex-col">
                    <Controller 
                     name="password"
                     control={control}
                     rules={{
                        required: true,
                        validate: validatePassword,
                     }}
                     render={({field: {onChange, onBlur, value}}) => (
                        <input 
                        {...register("password", {
                            required: true,
                            maxLength: 32
                          })}
                          onChange={(e) => {
                            onChange(e); // 값 변경 시 onChange 호출
                            setValue("password", e.target.value); // setValue로 패스워드 값 설정
                          }}
                           name="password"
                           autoCapitalize="none"
                           type="password"
                           placeholder="비밀번호를 입력하세요"
                           value={value}
                           className="w-full rounded-2xl flex border border-gray placeholder-gray px-5 py-3 font-display selection:bg-white focus:border-primary-green focus:outline-none focus:shadow-none text-lg font-light text-dark"
                           />
                     )} />
                     {errors.password && (
							<span className="font-display text-left mt-3 ml-3 text-xs text-red  ">
					      {errors.password.message}
							</span>
						)}

                    </div>
                    <div className="space-y-2 w-full flex flex-col">
                    <button className="mt-24 h-16 w-full appearance-none rounded-2xl hover:opacity-40 bg-primary-green font-display font-bold text-xl text-black ">
							{loading ? <SmallLoader width={24} height={24} /> : "로그인"}
						</button>
                    </div>
                </form>
                <div className="bg-favorite-gray mt-24 p-10 flex w-full flex-col items-start rounded-2xl text-black font-display">
                    <div className="  text-primary-green">🥹 아직 계정이 없나요?</div>
                    <div className="mt-3">관리자 계정이 필요하다면, 담당자에게 문의 하세요!</div>
                    <motion.button 
                        whileHover={{opacity: 0.8}}
                        whileTap={{scale:0.9}}
                        className="mt-3 bg-black w-1/4 items-center rounded-xl flex justify-center py-3 text-primary-green">✉️ SLACK DM</motion.button>

                </div>
                </div>
                <div className="flex flex-col ml-10 bg-primary-green h-screen w-full">

                </div>
                </div>
            </Layout>
      </>)
}