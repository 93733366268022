import Loader from '@/assets/loaders/loading.gif';

export const SmallLoader = ({ width, height }: { width: number; height: number }) => {
  return (
    <div>
      <img
        src={Loader}
        style={{ width: `${width}px`, height: `${height}px` }}
        alt="loader"
      />
    </div>
  );
};
