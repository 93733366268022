import { gql } from "@apollo/client";



export const GET_ALL_NEWS = gql`
    query getNewsByActiveStatus($active: Float!) {
        getNewsByActiveStatus(active: $active) {
            ok
            error
            results {
                id
                title
                subTitle
                thumbnail
                publicatedDate
                newsType
                editor
            }
        }
    }
`;




export const GET_ALL_CHARACTERS = gql`
     query seeAllCharactrersByAdmin {
        seeAllCharactrersByAdmin {
                ok
                error
                count
                results {
                    id
                    createdDate
                    name
                    nameEn
                    description
                    baseHp
                    baseSpeed
                    baseAgility
                    baseDefense
                    tier {
                        id
                        tierName
                        tier
                    }
                }
        }
  }
`;





export const GET_ALL_ADMINS = gql`
    query getAllAdmins {
        getAllAdmins {
            ok
            error 
            admins {
                id
                createdDate
                updatedDate
                active
                username
                name
                displayName
                password
                adminRole
                profileImageUrl
                token
                lastSignedAt
            }

        }

    }
`;