import { useEffect } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { setNodePlaceholderFromSelection } from '@/utils/lexical';
import { $getRoot, $getSelection } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';


export const CustomOnChangePlugin = ({ onContentChange }: { onContentChange: (html: string) => void }) => {
   const [editor] = useLexicalComposerContext();
   useEffect(() => {
      return editor.registerUpdateListener((listener) => {
         const stateAsJSON = listener.editorState.toJSON();
         listener.editorState.read(() => {
            const json = listener.editorState.toJSON();
            localStorage.setItem('editorContent', JSON.stringify(json));
            const htmlContent = $generateHtmlFromNodes(editor, null);
            onContentChange(htmlContent);
         });
         
         setNodePlaceholderFromSelection(editor);
      });
   }, [editor, onContentChange]);

   return null;
};
