import { gql } from "@apollo/client";





export const CREATE_NEWS = gql`
    mutation createNews($input: CreateNewsInput!) {
        createNews(input: $input) {
            ok
            error
            }
        }
`;



export const UPDATE_NEWS = gql`
    mutation updateNews($input: UpdateNewsInput!) {
        updateNews(input: $input) {
            ok
            error
        }
    }  
`;


export const ADD_CHARACTER = gql`
    mutation addCharacterByAdmin($input: CreateCharacterByAdmin!) {
        addCharacterByAdmin(input: $input) {
            ok
            error
        }
    }
`;


export const UPDATE_CHARACTERS = gql`
    mutation updateCharacterByAdmin($input: UpdateCharacterByAdmin!) {
        updateCharacterByAdmin(input: $input) {
            ok
            error
        }
    }  
`;



export const CREATE_ADMIN_ACCOUNT = gql`
    mutation createAdminAccount($input: CreateAdminAccountInput!) { 
        createAdminAccount(input: $input) {
            ok
            error
        }
    }
`;





export const ADMIN_ACTIVE_CONTROL = gql`
    mutation adminActiveControl($input: EditAdminInfo!) {
        adminActiveControl(input: $input) {
            ok
            error
        }
    }
`;




export const ADMIN_INFO_EDIT = gql`
    mutation editMyAdminInfo($input: EditAdminInfo!) {
        editMyAdminInfo(input: $input) {
            ok
            error
        }
    }
`;



