import {gql, useQuery} from "@apollo/client";
import { useAdmin } from "../__generated__/useAdmin";


export const ADMIN_ME_QUERY = gql`
    query useAdmin {
        useAdmin {
            id
            username
            displayName
            name
            adminRole
            lastSignedAt
            profileImageUrl
        }
    }
`;


export const useMe = () => {
    return useQuery<useAdmin>(ADMIN_ME_QUERY, {
        fetchPolicy: "cache-and-network"
    });
  }
  