import { useReactiveVar } from '@apollo/client';
import React from 'react';
import { isLoggedInVar } from './utils/apollo';
import { LoggedInRouter } from './router/logged-in-router';
import { AuthRouter } from './router/logged-out-router';


function App() {
  const isLoggedIn = useReactiveVar(isLoggedInVar);
  console.log(isLoggedIn)
  return (
    isLoggedIn ? <LoggedInRouter /> :  <AuthRouter />
  );
}

export default App;
