import { createAdminAccount, createAdminAccountVariables } from "@/__generated__/createAdminAccount";
import { CREATE_ADMIN_ACCOUNT } from "@/apis/mutation";
import { useMutation } from "@apollo/client";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { SmallLoader } from "../loader/small-loader";
import Toast from "../toasters/toast";
import { AdminRole } from "@/__generated__/globalTypes";


const NewAdminAddForm = ({admin, closeModal} : any) => {
    const [formData, setFormData] = useState(admin);
    const [isModified, setIsModified] = useState(false);
    const initialFormData = useRef(admin);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);



    const onCompleted = async (data:  createAdminAccount) => {
        const {createAdminAccount: {ok, error}} = data;
        if (ok) {
            setShowSuccessAlert(true);
            setTimeout(() => {
                setShowSuccessAlert(false);
                closeModal();
            }, 2000);
        } else {
            setShowErrorAlert(true);
            setTimeout(() => {
                setShowErrorAlert(false);
            }, 2000);
        }
    }




    const [createAdminAccount, {loading}] = useMutation<createAdminAccount, createAdminAccountVariables>(CREATE_ADMIN_ACCOUNT, {
        onCompleted, 
        refetchQueries: [
            'getAllAdmins'
        ]
    })


    useEffect(() => {
        setIsModified(JSON.stringify(formData) !== JSON.stringify(initialFormData.current));
      }, [formData]);




    const handleChange = (e: { target: { name: any; value: any; }; }) => {
        const { name, value } = e.target;
        setFormData((prev: any) => ({ ...prev, [name]: value }));
      };
  
      const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
       createAdminAccount({
        variables: {
            input: {
                name: formData.name,
                displayName: formData.displayName,
                username: formData.username,
                password: formData.password,
            }
        }
       })
      };
  

      return (
        <form onSubmit={handleSubmit} className="w-full mt-12">
        <div className="grid grid-cols-2  grid-rows-1 gap-4 mt-10">
          <div className="mb-4">
            <label className="block text-black font-bold text-xs font-display mb-2">username (로그인할 이메일 주소 - 아이디로 사용)</label>
            <input
              type="text"
              name="username"
              placeholder="어드민 멤버의 이메일을 작성하세요."
              value={formData.username}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
            />
         
          </div>
          <div className="mb-4">
          <label className="block text-black font-bold text-xs font-display mb-2">name(실명)</label>
            <input
              type="text"
              name="name"
              placeholder="실명을 작성하세요."
              value={formData.name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
            />
          </div>
          </div>
          <div className="mb-4">
            <label className="block text-black font-bold text-xs font-display mb-2">displayName</label>
            <input
             type="text"
              name="displayName"
              value={formData.displayName}
              onChange={handleChange}
              placeholder="디스플레이될 이름을 작성하세요."
              className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
            />
          </div>
          <div className="mb-4">
            <label className="block text-black font-bold text-xs font-display mb-2">ROLE</label>
            <select
                name="adminRole"
                value={formData.adminRole}
                onChange={handleChange}
                className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
              >
                <option value={AdminRole.MANAGER}>MANAGER</option>
                <option value={AdminRole.MEMBER}>MEMBER</option>
                <option value={AdminRole.SUPER}>SUPER</option>
              </select>
          </div>
          <div className="mb-4">
            <label className="block text-black font-bold text-xs font-display mb-2">password</label>
            <input
             type="text"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="초기 발급될 비밀번호를 작성하세요."
              className="w-full px-3 py-2 border rounded border-gray font-display focus:outline-none"
            />
          </div>
          <div className="flex justify-end">
            <button type="submit" className={`px-4 hover:opacity-60 py-2 bg-primary-green text-black font-display text-sm rounded ${!isModified || loading ? 'opacity-50 cursor-not-allowed' : ''} ${loading && 'items-center flex justify-center'}`} disabled={!isModified || loading}>
          {loading ? <SmallLoader height={24} width={24} /> : '등록'}
            </button>
          </div>
          {showSuccessAlert && (
          <Toast
            message="새로운 어드민 멤버가 등록되었습니다."
            show={showSuccessAlert}
            duration={2000}
            onClose={() => setShowSuccessAlert(false)}
            type="success"
          />
        )}
        {showErrorAlert && (
          <Toast
            message="오류가 발생했습니다."
            show={showErrorAlert}
            duration={2000}
            onClose={() => setShowErrorAlert(false)}
            type="error"
          />
        )}
        </form>

      );


}


export default NewAdminAddForm;