import { motion } from 'framer-motion';
import React from 'react';




const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1, // 자식 컴포넌트 간의 지연 시간
      },
    },
  };
  
  // 개별 motion.path의 variants
  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
      },
    },
  };
 



  const OpeningComponent = () => {
    return (
        <motion.div
        className="flex flex-col"
      style={{ backgroundColor: 'black', width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
        <motion.svg 
        width="140" height="32" viewBox="0 0 140 32" fill="none" xmlns="http://www.w3.org/2000/svg"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <g mask="url(#mask0_58_49)">
          <motion.path variants={itemVariants} d="M41.3662 18.608H58.2838L60.3204 12.892H43.3735L41.3662 18.608Z" fill="white"/>
          <motion.path variants={itemVariants} d="M36.834 31.5H53.6832L55.7051 25.8384V25.784H38.8412L36.834 31.5Z" fill="white"/>
            <motion.path variants={itemVariants} d="M74.5368 0H68.5053L57.3994 31.5H74.5173L76.5392 25.8384V25.784H65.3405L74.5368 0Z" fill="white"/>
            <motion.path variants={itemVariants} d="M44.3501 0H38.3186L30.1674 23.1214L22.0163 0H15.9848L20.1312 11.7141L15.1545 25.7246L6.02665 0.0544383H0L11.1742 31.5H19.1397L23.1738 20.1768L27.2127 31.5H33.1222L44.3501 0Z" fill="white"/>
            <motion.path variants={itemVariants} d="M95.3717 0H89.3402L78.2344 31.5H95.3131L97.3546 25.784H86.1755L95.3717 0Z" fill="white"/>
            <motion.path variants={itemVariants} d="M106.546 0H101.74L104.128 6.7751L106.546 0Z" fill="white"/>
            <motion.path variants={itemVariants} d="M134.091 0L130.101 11.2291L126.106 0H120.201L127.117 19.6176L122.863 31.5H128.894L133.085 19.6176L140 0H134.091Z" fill="white"/>
            <motion.path variants={itemVariants} d="M64.9161 0H47.9008L45.8936 5.71602H62.8795L64.9161 0Z" fill="white"/>
            <motion.path variants={itemVariants} d="M116.167 0H110.257L99.0293 31.5H105.061L108.953 20.4589L112.846 31.5H118.877L111.927 12.0111L116.167 0Z" fill="white"/>
            </g>
      </motion.svg>
      </motion.div>
      );
}


export default OpeningComponent;