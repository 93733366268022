export const theme = {
    list: {
        defaultProps: {
          ripple: false,
          className: "font-sans text-base font-normal w-80 mr-auto text-left",
        },
        styles: {
            base: {
                
                list: {
                    fontWeight: "hover:font-semibold focus:font-semibold"
                }, 
                item: {
                    initial: {
                         transition: "transition-all",
                         bg: "focus:bg-black hover:bg-black ",
                         color: "hover:text-primary-green text-black focus:text-primary-green active:text-primary-green",
                         outline: "outline-none",
                    }, 
                    selected: {
                        bg: "bg-transparent", 
                        color: "text-primary-green active:text-primary-green",
                        outline: "outline-none",
                    }
                },
                itemPrefix: {
                    initial: "text-black", 
                    selected: "text-primary-green"
                },
                itemSuffix: {
                    initial: "text-black", 
                    selected: "text-primary-green"
                }
            }
        },
       },
       card: {
        defaultProps: {
          variant: "filled",
          color: "#f2f6f6",
          shadow: true,
          className: "bg-lightgray",
        },
    },

  typography: {
    styles: {
        colors: {
            black : {
                color: "text-black",
            }
        }
    }
  }
  
}