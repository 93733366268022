

export function formatDate(epochTime: any) {
    const months = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
    const date = new Date(epochTime);
    const year = date.getFullYear().toString(); // 연도
    const month = months[date.getMonth()]; // UTC 기준 월
    const day = ('0' + date.getDate()).slice(-2); // UTC 기준 일
    const hours = ('0' + date.getHours()).slice(-2); // UTC 기준 시간
    const minutes = ('0' + date.getMinutes()).slice(-2); // UTC 기준 분
    return `${year}년 ${month} ${day}일 ${hours}:${minutes}`;
  }
  