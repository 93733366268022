import { UNDO_COMMAND, REDO_COMMAND } from 'lexical';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import React from 'react';
import { ActionsContainer } from '../ActionsContainer';
import { ActionButton } from '../ActionButton';
import {ArrowUturnLeftIcon, ArrowUturnRightIcon} from '@heroicons/react/16/solid';


export const CustomHistoryActions = () => {
   const [editor] = useLexicalComposerContext();
   return (
      <ActionsContainer title="">
         <ActionButton
            tooltip='Undo'
            onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
         >
               <ArrowUturnLeftIcon className="w-5 h-5" />
         </ActionButton>
         <ActionButton
                    tooltip='Redo'
            onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
         >
          <ArrowUturnRightIcon className="w-5 h-5" />
         </ActionButton>
      </ActionsContainer>
   );
};
