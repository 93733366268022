import Layout from '@/components/layouts/layout';
import React from 'react';




export default function Home () {
    return (
        <Layout hasNavbar={false} isFooter={false} isSidebar={true}>
        <div>
            
        </div>
        </Layout>
    )
}