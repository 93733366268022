import React from 'react';
import {motion} from 'framer-motion';




export const Footer = () => {
    return (
        <footer className="w-full flex flex-col fixed bottom-10  border-t border-black">
            <div className="mt-8 px-6 flex flex-row items-center justify-between w-full">
                <div className="font-display font-extralight select-none text-sm">
                    DEVS Inc.
                    <br />
                    주식회사 데브스
                </div>
                <div className="flex flex-col">
            </div>
            </div>
        </footer>
    )
}